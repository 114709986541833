// TODO check all events and add more if there are any

/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * All event listeners
 *
 * Source: https://businesshelp.snapchat.com/s/article/pixel-direct-implementation
 * */

// Pixel load event listener
jQuery(document).on("wpmLoadPixels", () => {

	if (wpmDataLayer?.pixels?.snapchat?.pixel_id && !wpmDataLayer?.pixels?.snapchat?.loaded) {
		if (wpm.canIFire("ads", "snapchat-ads")) wpm.loadSnapchatPixel()
	}
})

// AddToCart event
jQuery(document).on("wpmAddToCart", (event, product) => {

	try {
		if (!wpmDataLayer?.pixels?.snapchat?.loaded) return

		const data = {
			item_ids: [product.dyn_r_ids[wpmDataLayer.pixels.snapchat.dynamic_remarketing.id_type]],
		}

		snaptr("track", "ADD_CART", data)
		wpm.consoleLog("Snapchat Pixel: ADD_CART event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// VIEW_CONTENT event
jQuery(document).on("wpmViewItem", (event, product = null) => {

	try {
		if (!wpmDataLayer?.pixels?.snapchat?.loaded) return

		let data = {}

		if (product) {
			data.item_ids = [product.dyn_r_ids[wpmDataLayer.pixels.snapchat.dynamic_remarketing.id_type]]
		}

		snaptr("track", "VIEW_CONTENT", data)
		wpm.consoleLog("Snapchat Pixel: VIEW_CONTENT event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// SEARCH event
jQuery(document).on("pmwEvent:Search", () => {

	try {
		if (!wpmDataLayer?.pixels?.snapchat?.loaded) return

		snaptr("track", "SEARCH")
		wpm.consoleLog("Snapchat Pixel: SEARCH event sent")

	} catch (e) {
		console.error(e)
	}
})

// ADD_TO_WISHLIST event
jQuery(document).on("wpmAddToWishlist", () => {

	try {
		if (!wpmDataLayer?.pixels?.snapchat?.loaded) return

		snaptr("track", "ADD_TO_WISHLIST")
		wpm.consoleLog("Snapchat Pixel: ADD_TO_WISHLIST event sent")

	} catch (e) {
		console.error(e)
	}
})

// LOGIN event
jQuery(document).on("wpmLogin", () => {

	try {
		if (!wpmDataLayer?.pixels?.snapchat?.loaded) return

		snaptr("track", "LOGIN")
		wpm.consoleLog("Snapchat Pixel: LOGIN event sent")

	} catch (e) {
		console.error(e)
	}
})

// START_CHECKOUT event
jQuery(document).on("wpmBeginCheckout", () => {

	try {
		if (!wpmDataLayer?.pixels?.snapchat?.loaded) return

		snaptr("track", "START_CHECKOUT")
		wpm.consoleLog("Snapchat Pixel: START_CHECKOUT event sent")

	} catch (e) {
		console.error(e)
	}
})


// view order received page event
jQuery(document).on("wpmOrderReceivedPage", () => {

	try {
		if (!wpmDataLayer?.pixels?.snapchat?.loaded) return

		const data = {
			currency      : wpmDataLayer.order.currency,
			price         : wpmDataLayer.order.value.marketing,
			transaction_id: wpmDataLayer.order.id,
			item_ids      : wpm.getSnapchatOrderItemIds(),
		}

		snaptr("track", "PURCHASE", data)
		wpm.consoleLog("Snapchat Pixel: PURCHASE event sent", data)

	} catch (e) {
		console.error(e)
	}
})
